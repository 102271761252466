import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ComponentChangeDetectionService {

  private associateDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public associateDetail$: Observable<boolean> = this.associateDetail.asObservable();


  set associateChange(value: boolean) {
    this.associateDetail.next(value);
  }

  get associateChange(): boolean {
    return this.associateDetail.value;
  }


}
