import { Component, inject, Input, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TableComponent } from "@app/shared/components/table";
import { TableConfigModel } from "@app/shared/components/table/model";
import { ActivatedRoute, Router } from "@angular/router";
import { AssociationUnitTableKeyConst, AssociationUnitTableLabelConst } from "@app/pages/associations/shared/const";
import { ComponentChangeDetectionService } from "@app/pages/associations/service/component-change-detection.service";
import { UnitService } from "@app/shared/services/unit.service";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: 'unit-table',
  standalone: true,
  imports: [CommonModule, TableComponent],
  templateUrl: './unit-table.component.html',
  styleUrls: ['./unit-table.component.scss'],
  providers: [PaginationListService],
})
export class UnitTableComponent implements OnInit{

  @Input() category!: 'house' | null;

  @Input() units!: any[];

  tableConfig!: TableConfigModel;
  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);
  #changeComponent = inject(ComponentChangeDetectionService)
  #unit = inject(UnitService);
  pagination = inject(PaginationListService);

  ngOnInit() {
    this.pagination.setData(this.units, 10);
    this.initTable();
  }

  initTable(): void {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = AssociationUnitTableKeyConst;
    this.tableConfig.dataLabel = AssociationUnitTableLabelConst;
    this.tableConfig.dataSource = this.units;

    this.tableConfig.generateDataType();
    this.tableConfig.dataType["address"] = "custom";
    this.tableConfig.dataTotal = this.units.length;
    this.tableConfig.dataSource = this.pagination.getCurrentPageData();
    this.tableConfig.pageSize = 10;
  }

  updatePage(page: PageEvent): void {
    this.pagination.updatePageInfo(page);
    this.tableConfig.dataSource = this.pagination.getCurrentPageData();
  }

  public navigateToUnitDetail(unit: any) {
    this.#changeComponent.associateChange = true;
    this.#unit.unitName.next(unit.name);
    this.#router.navigate(["units", unit.slug], {
      queryParams: {
        tab: '1',
        isDetail: true
      },
      relativeTo: this.#activatedRoute,
    });
  }

}
