<app-table
  [httpPagination]="true"
  [tableConfig]="tableConfig"
  [category]="category"
  (selectData)="navigateToUnitDetail($event)"
  (pageChange)="updatePage($event)"
>
  <ng-template #customTemplate let-key="key" let-data="data">
    <ng-container *ngIf="key === 'address'">
      <div
        class="align-center gap-10 text-underline c-pointer"
        style="font-size: 14px !important; color: #91466a"
      >
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHAW7VtIk_8C544nvEEjO8ViOwmoGa6zv4kA_ObFh3BmM87bf0wQhQANM1GO3scr7zdt8&usqp=CAU"
          alt="Property Image"
          style="width: 72px; border-radius: 4px"
        />
        {{ data.address }}
      </div>
    </ng-container>
  </ng-template>
</app-table>
